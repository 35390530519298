.policy-bg-mask {
    background-color: #5580ea;
    padding: 5% 0 3% 0;
  }

  .policy_textlabel_heading {
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46 !important;
    color: #fff;
  }  

  .Last-modified-text {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78 !important;
    color: #fff;
  }

  .privacy_policy_view_box {
    padding: 4% 7%;
    border-radius: 10px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    margin-bottom: 27px;
  }

  .view_box_header_text {
    font-family: Inter !important;
    font-size: 2.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
    padding-bottom: 3%;
  }

  .view_box_content_text {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67 !important;
    text-align: justify;
    color: #394052;
  }

  .view_box_content_text_2 {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67 !important;
    text-align: justify;
    color: #394052;
    padding-top: 3%;
  }

  @media (max-width: 1279px){
    .policy-image {
      width: 100%
    }
    .policy_textlabel_heading {
      font-size: 36px !important;
    }
    .Last-modified-text {
      font-size: 18px !important;
    }
    .view_box_header_text {
      font-size: 28px !important;
    }
    .view_box_content_text {
      font-size: 16px !important;
    }
    .view_box_content_text_2 {
      font-size: 16px !important;
    }
  } 
  
  @media (max-width: 959px){
    .policy_textlabel_heading {
      font-size: 32px !important;
    }
    .Last-modified-text {
      font-size: 16px !important;
    }
    .view_box_header_text {
      font-size: 24px !important;
    }
  }
  
  @media (max-width: 599px){
    .policy-image {
      /* width: 60%; */
      padding: 10px 0;
    }
    .small-screen-justify-center {
      justify-content: center !important;
    }
    .policy_textlabel_heading {
      font-size: 26px !important;
    }
    .Last-modified-text {
      font-size: 14px !important;
    }
    .view_box_header_text {
      font-size: 18px !important;
    }
    .view_box_content_text {
      font-size: 12px !important;
    }
    .view_box_content_text_2 {
      font-size: 12px !important;
    }
    .small-screen-centered {
      padding-left: 0 !important;
      text-align: center;
    }
    .privacy_policy_view_box {
      margin-bottom: 20px;
    }
  }
