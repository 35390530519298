.miniCard {
  background-color: #fff;
  border-radius: 10px !important;
  cursor: "pointer";
}

.competitiveExam {
  border: solid 2px #f7dbd4;
}

.competitiveExam:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}

.schoolTution {
  border: solid 2px #dfddef;
  margin-top: 52px;
}

.schoolTution:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}

.examTestSeries {
  border: solid 2px #cfeffe;
  margin-top: 52px;
}


.examTestSeries:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  }

  .Potential-content .justify-content-center-small-screen{
    display: flex;
    justify-content: center;
  }
  .icon-center {
    align-self: center;
  }

  .align-last {
    align-self: center;
    text-align-last: left;
    padding-left: 5px;
  }