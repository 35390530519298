.landingPageContainer {
  background: "linear-gradient(180deg, #f8f9ff 70%, #fff 30%)";
}

.landingPage-attend-block{
    display: flex;
    justify-content: center;
}
.classroomWrapper {
  display: flex;
  justify-content: center;
  background-color: white;
}
.liveClassContainer {
  background-color: #f8f9ff;
}
.liveClassHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carouselContainer {
  padding: 6% 0% 6% 6% !important;
  cursor: pointer;
}

.classroomInformationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-left: 15%;
}

.classroomInformation-button {
  max-width: 190px;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background-color: #5580ea !important; */
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  margin-top: 23px !important;
  background-image: linear-gradient(87deg, #f7ba51 -15%, #f79f84 118%) !important;
  box-shadow: 1px 10px 10px  #f79f84 !important;
  cursor: pointer;
}

.classroom-button-label {
  font-family: Inter;
  font-size: 20px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
  text-transform: capitalize;
}

.footer-success {
  display: flex;
  justify-content: space-between;
  background-size: contain;
  width: 100%;
}
.flex-item-center {
  display: flex;
  align-items: center;
}