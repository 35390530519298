.width-70per {
  width: 80%;
}

.Course-Details-Title {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #5580ea;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.Change-Btn {
  width: 80px;
  height: 30px;
  border-radius: 5px !important;
  margin-left: 20px !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  background-color: #ffc101 !important;
  border: none !important;
}

.Change-Text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.Free-Trial-Course-Btn {
  width: 130px;
  height: 45px;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  background-image:  linear-gradient(87deg, #f7ba51 -15%, #f79f84 118%) !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  /* background-color: #5580ea !important; */
  box-shadow: 3px 6px 10px  #f79f84 !important
  
}

.Free-Trial-Course-Text {
  /* height: 20px; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.Login-Course-Btn {
  width: 130px;
  height: 45px;
  margin: 0 0 0 10px !important;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.Login-Course-Text {
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff;
  text-transform: capitalize;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.textlabel_heading {
  font-family: Inter !important;
  font-size: 3.3vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}

.textlabel_heading_course {
  font-family: Inter !important;
  font-size: 2.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}

.our-repeater-course-text {
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* text-align: justify; */
  color: #394052;
  margin-top: 20px !important;
}

a:-webkit-any-link {
  color: #5580ea;
}

.learn-more-text {
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* text-align: justify; */
  color: #5580ea;
  margin-bottom: 20px !important;
}

.feature_box {
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  cursor: pointer;
  max-width: 400px !important;
  text-align: center;
  padding: 10%;
  height:330px
  /* min-height: 450px; */
}

.header-img {
  width: 100%;
  /* height: 602px  */
}

.feature_img {
  height: 72px;
  /* width: 100px; */
  padding: 10% 0;
}

.feature_title_course {
  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding-bottom: 18px;
  height: 50px;
}
.feature_title_box {
  height: 100px;
}

.feature_desc {
  font-size: 1vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  height: 100px;
}
.feature_desc_box {
  height: 100px;
  font-family: Inter !important;
}

.textlabel_knowmore {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #5580ea;
  margin-top: -20;
}

.course-highlight-view-box {
  height: 136px;
  border-radius: 16px;
  box-shadow: 0 -8px 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #f8f9ff;
  padding: 9%;
}

.course-highlight-image {
  width: 60px !important;
  height: 60px !important;
  object-fit: contain;
}

.course-highlight-header {
  font-family: Inter !important;
  font-size: 1vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: left;
  color: #464c5d;
}
.faq-below-text {
  /* width: 800px;
    height: 66px; */
  margin: 7px 0 0 0;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* letter-spacing: 2px; */
  color: #4b4e53;
}
.get_assi_text {
  font-family: Inter !important;
  font-size: 1.6vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #4b4e53;
  width: 90%;
}
/* .sugg-below-text{
    width: 700px;
    height: 66px;
    margin: 7px 0 0 0;
    font-family: Inter;
    font-size: 22px;
    font-weight: 510;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 2px;
    color: #5b5d63;
  
  } */

.course-highlight-text {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.batch-view-box {
  border-radius: 5px;
  box-shadow: 0 0 13px 3px rgba(97, 116, 169, 0.05);
  background-color: #fff;
  padding: 23px 17px;
}

.textlabel_batches {
  font-family: Inter !important;
  font-size: 2.7vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: hsl(223, 9%, 38%);
}

.textlabel_batchname {
  font-family: Inter !important;
  font-size: 1.95vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32 !important;
  color: #394052;
  margin: 20px 0 !important;
}

.textlabel_viewsschedule {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
  text-decoration: underline;
  cursor: pointer;
}

.textlabel_startdate {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19 !important;
  color: #394052;
  margin: 9px 0 !important;
}

.subject_view_box {
  width: 64px;
  height: 25px;
  border-radius: 4px;
  background-color: #e1e9fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textlabel_language_hindi {
  font-family: Inter !important;
  font-size: 1vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
}

.spacing-60 {
  padding: 60px 0;
}

.view_viewallbox_course {
  width: 14vw;
  height: 4.2vw;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  margin-left: 1px;
}

.textlabel_viewall_course {
  font-family: Inter !important;
  font-size: 1.4vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #fff;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
  text-transform: capitalize;
}

.view_box_subject {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  padding: 10% 16%;
  width: 110px;
}

.textlabel_subjectname {
  /* width: 105px; */
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding: 5% 0;
}

.textlabel_viewsyllabus {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding-bottom: 12%;
  text-decoration: underline;
}

.course_sub_view_box {
  border-radius: 16px;
  box-shadow: 0 -8px 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
  padding: 3.5%;
}

.sub_item_view_box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 5% 5%;
  width: 75%;
  height: 60%;
}

.textlabel_personal {
  font-family: Inter !important;
  font-size: 1.68vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
}

.textlabel_details {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  margin-top: 12px !important;
}

.features_included_view_box_border {
  border-radius: 9px;
  border: solid 0.5px #557fe9;
}

.textlabel_features {
  font-family: Inter !important;
  font-size: 1.95vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18 !important;
  color: #394052;
}

.view_subscription {
  width: 100%;
  padding: 2%;
  border-radius: 5px;
  background-color: #f1f5fe;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view_selected {
  border-radius: 5px !important;
  background-color: #5580ea !important;
  padding: 5% 0 !important;
}

.textlabel_selected {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06 !important;
  color: #fff;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
  text-transform: capitalize;
}

.textlabel_notselected {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06 !important;
  color: #394052;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
  text-transform: capitalize;
}

.textlabel_live {
  font-family: Inter !important;
  font-size: 1.55vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
  padding: 3% 0;
}

.btn_buyfullcourse {
  border-radius: 9px !important;
  background-color: #5580ea !important;
  padding: 2% 6% !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.textlabel_buyfullcourse {
  font-family: Inter !important;
  font-size: 1.32vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.textlabel_discount {
  font-family: Inter !important;
  font-size: 1.55vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.textlabel_price {
  font-family: Inter !important;
  font-size: 2.2vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #5580ea;
}

.margin-top-minus {
  margin-top: -115px !important;
}

.quote-image {
  width: 81.6px;
  height: 60px;
  object-fit: contain;

  /* position: absolute;*/
  /* z-index: -1;  */
}

.Hear-from-our-text {
  font-family: Inter !important;
  font-size: 2.6vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
  /* padding-left: 45px; */
  margin-left: -25px !important;
}

.view_name_text {
  font-family: Inter !important;
  font-size: 1.85vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #000;
}

.view_parent_text {
  font-family: Inter !important;
  font-size: 1.75vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #677788;
  margin-top: 8px !important;
}

.headerImageLogo {
  height: 50px !important;
  width: 122.8px !important;
}

.overlap_faq_box {
  /* width: 1170px;
    height: 164px;
    margin: 100px 0.4px 854px 2px;
    padding: 49px 61px 48px 55px; */
  padding: 2%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  margin-top: -5% !important;
}

@media (max-width: 1279px) {
  .course-highlight-image {
    width: 45px !important;
    height: 45px !important;
  }

  .course-highlight-header {
    font-size: 20px !important;
  }

  .course-highlight-text {
    font-size: 16px !important;
  }

  .headerlogo {
    height: 50px !important;
    width: 110px !important;
  }
  .view_box {
    padding: 30px 12px;
  }
  .course-highlight-view-box {
    padding: 18px;
  }
  .Change-Btn {
    margin: 0 10px !important;
  }
  .view_box_subject {
    padding: 5%;
  }
  .quote-image {
    width: 70px;
    height: 50px;
  }
  .feature_title_course {
    font-size: 18px !important;
  }
  .feature_desc {
    font-size: 12px !important;
  }
  .textlabel_knowmore {
    font-size: 14px !important;
  }
  .textlabel_heading {
    font-size: 34px !important;
  }
  .textlabel_heading_course {
    font-size: 34px !important;
  }
  .our-repeater-course-text {
    font-size: 18px !important;
  }
  .enroll-now-small-text {
    font-size: 16px !important;
  }
  .faq-below-text {
    font-size: 18px !important;
  }
  .small-chapter-name {
    font-size: 18px !important;
  }
  .textlabel_startdate {
    font-size: 14px !important;
  }
  .textlabel_language_hindi {
    font-size: 14px !important;
  }
  .textlabel_viewsschedule {
    font-size: 13px !important;
  }
  .view_text {
    font-size: 12px !important;
  }
  .margin-top-minus {
    margin-top: -35px !important;
  }
}

@media (max-width: 959px) {
  .course-highlight-image {
    width: 45px !important;
    height: 45px !important;
  }

  .margin-top-minus {
    margin-top: 15px !important;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }

  /* .course-highlight-header {
      font-size: 18px !important;
    }

    .course-highlight-text {
      font-size: 14px !important;
    } */
  .feature_img {
    height: 60px;
    /* width: 60px; */
    padding: 30px 0 20px 0;
  }

  .subject_image {
    height: 60px !important;
    width: 60px !important;
  }

  .headerImageLogo {
    height: 40px !important;
    width: 80px !important;
  }

  .quote-image {
    height: 50px !important;
    width: 50px !important;
  }
  .Change-Btn {
    margin: 0 10px !important;
  }

  .Free-Trial-Course-Btn {
    width: 95px;
    height: 35px;
    margin-right: 5px !important;
  }

  .Free-Trial-Course-Text {
    font-size: 14px;
  }

  .Login-Course-Btn {
    width: 95px;
    height: 35px;
    margin: 0 !important;
  }

  .Login-Course-Text {
    font-size: 14px;
  }
  .Course-Details-Title {
    max-width: 170px;
  }
  .textlabel_heading {
    font-size: 28px !important;
  }
  .textlabel_heading_course {
    font-size: 28px !important;
  }
  .our-repeater-course-text {
    font-size: 14px !important;
  }
  .learn-more-text {
    font-size: 14px !important;
  }
  .enroll-now-small-text {
    font-size: 14px !important;
  }

  .textlabel_batches {
    font-size: 28px !important;
    margin-top: 20px !important;
  }
  .get_assi_text {
    font-size: 20px !important;
  }
  .course-highlight-header {
    font-size: 17px !important;
  }
  .course-highlight-text {
    font-size: 14px !important;
  }
  .textlabel_personal {
    font-size: 18px !important;
  }
  .textlabel_details {
    font-size: 14px !important;
  }
  .textlabel_features {
    font-size: 16px !important;
  }
  .textlabel_selected {
    font-size: 14px !important;
  }
  .textlabel_notselected {
    font-size: 14px !important;
  }
  .textlabel_live {
    font-size: 14px !important;
  }
  .textlabel_discount {
    font-size: 14px !important;
  }
  .textlabel_price {
    font-size: 14px !important;
  }
  .textlabel_buyfullcourse {
    font-size: 14px !important;
  }
  .textlabel_viewall_course {
    font-size: 14px !important;
  }
  .textlabel_subjectname {
    font-size: 14px !important;
  }
  .textlabel_batchname {
    font-size: 20px !important;
    margin: 15px 0 !important;
  }
  .textlabel_startdate {
    font-size: 12px !important;
  }
  .textlabel_language_hindi {
    font-size: 12px !important;
  }
  .textlabel_viewsschedule {
    font-size: 12px !important;
  }
  .Hear-from-our-text {
    font-size: 19px !important;
  }
  .view_name_text {
    font-size: 18px !important;
  }
  .view_parent_text {
    font-size: 16px !important;
  }
  .view_text_desc {
    font-size: 14px !important;
  }
  .view_img {
    width: 60px;
  }
  .faq-below-text {
    font-size: 16px !important;
  }
  .small-chapter-name {
    font-size: 16px !important;
  }
  .view_text {
    font-size: 12px !important;
  }
  .sub_item_view_box {
    padding: 8% 9%;
  }
}

@media (max-width: 599px) {
  .textlabel_batches {
    font-size: 22px !important;
  }
  .get_assi_text {
    font-size: 16px !important;
  }
  .quote-image {
    height: 30px !important;
    width: 30px !important;
  }
  .width-70per {
    width: 90%;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }
  .headerImageLogo {
    height: 25px !important;
    width: 50px !important;
  }
  .Course-Details-Title {
    font-size: 14px !important;
  }

  .course-highlight-image {
    width: 30px !important;
    height: 30px !important;
  }

  .imgTick {
    height: 18px;
    width: 22px;
  }
  .Change-Btn {
    width: 60px;
    margin-left: 2px !important;
  }
  .Change-Text {
    font-size: 12px;
  }
  .Course-Details-Title {
    max-width: 120px;
  }
  .Free-Trial-Course-Btn {
    width: 66px;
  }

  .Free-Trial-Course-Text {
    font-size: 12px;
  }

  .Login-Course-Btn {
    width: 60px;
  }

  .Login-Course-Text {
    font-size: 12px;
  }
  .feature_desc {
    font-size: 12px !important;
  }
  .Hear-from-our-text {
    font-size: 17px !important;
    padding-bottom: 10px;
    margin-left: 8px !important;
    margin-top: -10% !important;
  }
  .course-highlight-header {
    font-size: 13px !important;
  }
  .course-highlight-text {
    font-size: 12px !important;
  }
  .textlabel_heading {
    font-size: 26px !important;
  }
  .textlabel_heading_course {
    font-size: 26px !important;
  }
  .view_viewallbox_course {
    height: 30px !important;
  }
  .textlabel_viewall_course {
    font-size: 12px !important;
  }
  .feature_title_course {
    font-size: 16px !important;
  }
  .textlabel_knowmore {
    font-size: 14px !important;
  }
  .small-screen-less-padding {
    padding: 5px !important;
  }
  .textlabel_personal {
    font-size: 14px !important;
  }
  .textlabel_details {
    font-size: 12px !important;
  }
  .view_text {
    font-size: 14px !important;
  }
  .sub_item_view_box {
    padding: 14% 10%;
  }
}

@media (max-width: 399px) {
  .Course-Details-Title {
    max-width: 70px;
  }
  /* .Hear-from-our-text {
      font-size: 13px !important;
    } */
  .textlabel_personal {
    font-size: 14px !important;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }
  .course-highlight-header {
    font-size: 13px !important;
  }
  .sub_item_view_box {
    padding: 20% 10%;
  }
}
