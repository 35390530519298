.subjectTags{
    font-family:  Inter;
    font-size:  1.05em;
    font-weight: 600 !important;
    font-stretch:  normal;
    font-style:  normal;
    
 
}

.competitiveTags {
    color:  #e06e53;
}

.tutionTags {
    color: #8179c0 ;
}

.examSeriesTags {
    color: #40c1fa;
}

