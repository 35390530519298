.left-section-panel {
  /* height: inherit; */
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* border-radius: 8px; */
}

.left-section-grid {
  width: 100%;
  /* height: inherit; */
  background-color: #5580ea;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15%;
  text-align: center;
}

.zoho-left-panel-img {
  width: 70%;
  height: 220px;
  object-fit: contain;
}

.form-modal {
  justify-content: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  border-radius: 20px;
  max-height: 100%;
  overflow: scroll;
  width: 60%;
  /* padding: 3%; */
  overflow: auto;
}

.close-icon-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.Registration {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #394052;
}
.Enroll {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  margin: 5% !important;
  color: #e6e9f3;
}
.textfield {
  width: 100% !important;
  border-radius: 6px;

  font-size: 15;
  /* opacity: 0.6; */
  border-radius: 3.9px;
  /* border: solid 0.8px #5580ea; */
  background-color: #f9faff;
}

.textfield .MuiOutlinedInput-input {
  /* color: #000000 !important; */
}

.select {
  width: 100% !important;
  border-radius: 6px;
  color: #3f5267;
  font-size: 15;
  opacity: 1;
  border-radius: 3.9px;
  border: solid 0.8px #5580ea;
  background-color: #f9faff;
  padding: 16px 14px;
}

.select-course {
  width: 100% !important;
  border-radius: 6px;
  color: #3f5267;
  font-size: 15;
  opacity: 1;
  border-radius: 3.9px;
  border: solid 0.8px #5580ea;
  background-color: #f9faff;
  padding: 16px 14px;
}

.marginBottom {
  margin-bottom: 2% !important;
}
.marginTop {
  margin-top: 2% !important;
}

.form-label {
  opacity: 1 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  margin-bottom: 2% !important;
  white-space: nowrap !important;
}

.checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3a60d7;
}

.policy {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-terms {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #394052;
  margin-left: 2%;
}

.submit-btn {
  width: 130px;
  padding: 3%;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  background-color: #5580ea !important;
  margin-left: 5% !important;
}

.cancel-btn {
  width: 130px;
  padding: 3%;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  background-color: #e3ebff !important;
}

.submit-zoho-form-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.cancel-zoho-form-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #394052;
}
.batchInputContainer {
  flex-wrap: nowrap !important;
  gap: 10px;
}

.zoho-course-item > .MuiTypography-body1 {
  line-height: unset !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  font-size: 14px !important;
  color: #394052;
}

.zoho-course-item.MuiListItemText-root {
  margin: 0 !important;
  font-size: 14px !important;
  color: #394052;
}

@media only screen and (max-width: 1300px) {
  .form-label {
    font-size: 13px !important;
  }
  .submit-zoho-form-text {
    font-size: 12px;
  }
  .cancel-zoho-form-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1050px) {
  .form-label {
    font-size: 12px !important;
  }
  .submit-zoho-form-text {
    font-size: 12px;
  }
  .cancel-zoho-form-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .batchInputContainer {
    flex-wrap: wrap !important;
    gap: 0px;
  }
  .batchInputContainer:nth-child(2) {
    margin-top: 100px;
  }
  .form-modal {
    width: 90%;
  }
  .marginBottomSmall {
    margin-bottom: 2% !important;
  }
}
