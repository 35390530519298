.img_feedback_main {
    width: 59px;
    height: 59px;
    margin: 0 40px 0 0;
    object-fit: contain;
  }

  .Performance_Analysis_text {
    font-family: Inter !important;
    font-size: 32px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53 !important;
    color: #394052;
    display: flex;
    align-items: center;
  }

.report_image {
    width: 84px;
    height: 84px;
    object-fit: contain;
  }

  .report_title {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #394052;
    margin: 4% 15% 2% 15% !important;
  }

  .report_desc {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center;
    color: #394052;
    margin: 0 5% !important;
  }

  .horizonal-line {
    margin: 3% 0;
    border: solid 1px #bac6e4;
  }

  .Enroll-Now-button {
    width: 228px;
    height: 60px;
    padding: 1% 3% !important;
    border-radius: 5px !important;
    background-color: #5580ea !important;
    -ms-transform: skewX(-6deg);
    transform: skewX(-6deg);
    margin: 3% 0 0 3% !important;
  }

  .Enroll-Now-btn-text {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #fff;
    text-transform: capitalize;
    -ms-transform: skewX(1deg);
    transform: skewX(1deg);
  }

@media (max-width: 1279px) {
  .Performance_Analysis_text {
    font-size: 30px !important;
  }
  .report_image {
    width: 60px;
    height: 60px;
  }
  .report_title {
    font-size: 18px !important;
    margin: 4% 0 2% 0 !important;
  }
  .report_desc {
    font-size: 14px !important;
    margin: 0 !important;
  }
  .performance_report_modal {
    width: 85% !important;
  }
  .fix-spacing {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media (max-width: 959px) {
  .img_feedback_main {
    width: 55px;
    height: 55px;
    margin: 0 20px 0 0;
  }
  .Performance_Analysis_text {
    font-size: 26px !important;
  }
  .report_image {
    width: 50px;
    height: 50px;
  }
  .report_title {
    font-size: 16px !important;
  }
  .report_desc {
    font-size: 13px !important;
  }
  .Enroll-Now-button {
    width: 210px;
    height: 50px;
  }
  .Enroll-Now-btn-text   {
    font-size: 20px !important;
  }
}

@media (max-width: 599px) {
  .img_feedback_main {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
  }

  .Performance_Analysis_text {
    font-size: 20px !important;
  }

  .report_image {
    width: 45px;
    height: 45px;
  }

  .report_title {
    font-size: 16px !important;
    margin: 4% 0 2% 0 !important;
  }

  .report_desc {
    font-size: 12px !important;
  }

  .Enroll-Now-button {
    width: 200px;
    height: 50px;
    padding: 1% 3% !important;
    margin: 3% 0 0 3% !important;
  }

  .Enroll-Now-btn-text {
    font-size: 18px !important;
  }
  .top-spacing-small-screen {
    padding-top: 5% !important;
  }
  .bottom-spacing-small-screen {
    padding-bottom: 10% !important;
  }
  .center-content {
    display: flex;
    place-content: center;
  }
 
}