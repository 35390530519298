.Empowering-Students-text {
    font-family: Inter !important;
    font-size: 2.6vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26 !important;
    color: #394052;
    margin-bottom: 25px !important;
  }

  .Own-the-unlimited-text {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #677788;
  }

  .TECHNOLOGY-text {
    font-family: Inter !important;
    font-size: 1.45vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29 !important; 
    text-align: center;
    color: #5580ea;
  }

  .Our-Learning-Manage-text {
    font-family: Inter !important;
    font-size: 2.6vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26 !important;
    text-align: center;
    color: #394052;
    max-width: 60%;
  }

  .padding-top-bottom {
    padding: 5% 0;
  }

  .left-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3.5%;
}

  .right-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 3.5%;
  }

.block-img {
  width: 100%;
  /* height: 314px; */
  object-fit: contain;
}

  .block-text-1 {
    font-family: Inter !important;
    font-size: 3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
    margin-bottom: 16px !important;
  }

  .block-text-2 {
    font-family: Inter !important; 
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56 !important;
    color: #394052;
  }

  .sub-block-rectangle {
    padding: 5% 7%;
    border-radius: 4px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    margin: 10px 0;
  }

  .sub-block-header-text {
    font-family: Inter !important;
    font-size: 2vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .sub-block-content-text {
    font-family: Inter !important;
    font-size: 1.12vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75 !important;
    color: #394052;
    margin-top: 15px !important;
  }

  .technology-main-block-style {
    padding-right: 15%;
  }

  .react-player__preview {
    border-radius: 10px;
  }

/* Remove extra css */

.Competitive-container-info{
  display: flex;
  background: #f8f9ff;
  justify-content: center;
}
.technology-main-block-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.main-block-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  
}


.justifyContent-center {
  display: flex;
  justify-content: center;
}

  @media (max-width: 1279px) {
    .Empowering-Students-text {
      font-size: 34px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 20px !important;
    }
    .TECHNOLOGY-text {
      font-size: 21px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 34px !important;
      max-width: 70%;
    }
    .block-text-1 {
      font-size: 34px !important;
    }
    .block-text-2 {
      font-size: 18px !important;
    }
    .sub-block-header-text {
      font-size: 24px !important;
    }
    .sub-block-content-text {
      font-size: 16px !important;
    }
    .technology-main-block-style {
      padding-right: 10%;
    }
  }

  @media (max-width: 959px) {
    .Empowering-Students-text {
      font-size: 30px !important;
      margin-bottom: 15px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 16px !important;
      margin-bottom: 15px !important;
    }
    .TECHNOLOGY-text {
      font-size: 20px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 30px !important;
      max-width: 90%;
    }
    .block-text-1 {
      font-size: 26px !important;
    }
    .block-text-2 {
      font-size: 14px !important;
    }
    .sub-block-header-text {
      font-size: 20px !important;
    }
    .sub-block-content-text {
      font-size: 14px !important;
    }
    /* .video-display-size {
      height: 100% !important;
    } */
  }

  @media (max-width: 599px) {
    .Empowering-Students-text {
        font-size: 28px !important;
        margin-bottom: 10px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 14px !important;
      margin-bottom: 10px !important;
    }
    .TECHNOLOGY-text {
      font-size: 16px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 26px !important;
      max-width: 95%;
    }
    .block-text-1 {
      font-size: 24px !important;
    }
    .block-text-2 {
      font-size: 14px !important;
    }
    .sub-block-header-text {
      font-size: 16px !important;
    }
    .sub-block-content-text {
      font-size: 12px !important;
    }
    .left-block {
      padding: 5% 0;
    }
    .right-block {
      padding: 5% 0;
    }
    .technology-main-block-style {
      padding-right: 0;
      text-align: center;
    }
    .padding-top-bottom {
      padding: 0;
    }
    .video-display-size {
      height: 250px !important;
    }
  }

