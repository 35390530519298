.textlabel_about_us_heading {
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43 !important;
    color: #394052;
  }

  .about_us_heading_underline {
    border-bottom: 13px solid #ffebc1;
    width: fit-content;
    display: inline-block;
    line-height: 0.5;
  }

  .img_work_hard {
    width: 100%;
    height: fit-content;
    object-fit: contain;
  }  

  .features_view_box {
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    margin-top: -4%;
    margin-bottom: 5%;
  }

  .If-a-sporting-activity-text {
    font-family: Inter !important;
    font-size: 2vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal !important;
    text-align: center;
    color: #394052;
  }

  .feature_image_source {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .feature_title_text {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41 !important;
    text-align: center;
    color: #394052;
    margin: 4% 11% !important;
  }

  .feature_desc_text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81 !important;
    text-align: center;
    color: #394052;
  }

  .For-item-selected-title {
    font-family: Inter !important;
    font-size: 2.2vw !important;
    font-weight: bold !important;
    color: #394052;
    padding: 5% 0;
    cursor: pointer;
  }

  .For-item-not-selected-title {
    font-family: Inter !important;
    font-size: 1.85vw !important;
    font-weight: bold !important;
    color: #9dadbe;
    padding: 5% 0;
    cursor: pointer;
  }

  .selected-line {
    padding-bottom: 15px;
    border-bottom: 3px solid #5580ea;
    width: fit-content;
    margin: 0;
  }

  .not-selected-line {
    padding-bottom: 15px;
    border-bottom: 3px solid #cad9e9;
    width: fit-content;
    margin: 0;
  }

  .for_student_view_box {
    padding: 3% !important;
    border-radius: 10px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    height: 90px;
    display: flex;
    align-items: center;
  }

  .for_student_title {
    font-family: Inter !important;
    font-size: 1.85vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #3f526d;
    margin-bottom: 2% !important;
    padding-left: 16px;
  }

  .for_student_details {
    font-family: Inter !important;
    font-size: 1.39vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4 !important;
    color: #394052;
    margin-left: 3% !important;
  }

  .best-office-employee-img {
    width: 100%;
    /* height: 460px; */
    object-fit: contain;
  }

  .textlabel_values_ethos {
    margin: 0 0 34px 0 !important;
    font-family: Inter !important;
    font-size: 2.65vw !important;
    font-weight: bold !important;
    line-height: 1.34 !important;
    color: #394052;
  }

  .textlabel_values_ethos_desc {
    font-family: Inter !important;
    font-size: 1.4vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9 !important;
    color: #394052;
  }

  .Our_values_rectangle {
    padding: 5%;
    border-radius: 16px;
    box-shadow: 0 12px 48px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    position: relative;
  }

  .Our_values_textlabel {
    font-family: Inter !important;
    font-size: 2.65vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92 !important;
    color: #394052;
  }

  .With_the_student_text {
    margin: 10px 0 0;
    font-family: Inter !important;
    font-size: 1.7vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64 !important;
    color: #394052;
  }

  .tiltSliderDots {
    padding: 10px 0 0px 0;
  }

  .slick-slider.tiltSliderDots .slick-dots {
    justify-content: flex-end;
    position: absolute !important;
    height: 20px;
    top: -35px;
  }
  .slick-slider.tiltSliderDots .slick-dots li {
    width: 22.9px;
    height: 20px;
    margin: 0 8px;
  }
  .slick-slider.tiltSliderDots .slick-dots li button {
    height: 20px;
    width: 20px;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
  }
  .slick-slider.tiltSliderDots .slick-dots li::after {
    width: 22.9px;
    height: 20px;
    border-radius: 3px;
    -ms-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }

  .slick-slider.tiltSliderDots .slick-dots li.slick-active::after {
    width: 22.9px;
    height: 20px;
    border-radius: 3px;
    -ms-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }

  .slick-slider.about-carousel .slick-next {
    top: -20px !important;
    height: 30px;
    right: 3% !important;
    width: 34px;
  }
  
  .slick-slider.about-carousel .slick-prev {
    top: -20px !important;
    right: 8% !important;
    left: auto !important;
    height: 30px;
    width: 34px;
  }

  .pause_icon {
    width: 33px;
    height: 39px;
    font-family: Inter !important;
    font-size: 92px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87 !important;
    color: #5580ea;
    position: absolute;
    top: -5%;
    left: 5%;
  }

  .leadership_team_textlabel {
    margin: 0 0 1% 0 !important;
    font-family: Inter !important;
    font-size: 2.65vw !important;
    font-weight: bold !important;
    line-height: 1.92 !important;
    color: #394052;
  }

  .leadership_team_details {
    margin: 0 0 2% 0 !important;
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5 !important;
    color: #394052;
  }

  .leader_rectangle {
    /* border-radius: 9px;
    box-shadow: 0 12px 48px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5% 8% 5%; */
    padding: 20px 10px;
    border-radius: 3px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    width: 59%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    /* height: calc(100% - 32px); */
    margin-bottom: 8%;
  }

  .leader_image {
    width: 162px;
    height: 185px;
    object-fit: contain;
    margin-top: -10%;
  }

  .leader_name {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
    margin-bottom: 2% !important;
    margin-top: 4% !important;
    text-align: center;
  }

  .leader_details {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
     height: 120px;
     overflow: auto;
  }
  
  .leader_less_details {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .leader_know_more {
    font-family: Inter !important;
    font-size: 1.4vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #5580ea;
    padding-top: 10px;
  }

  .leader-profile-avatar {
    border-radius: 10% !important;
    height: 185px !important;
    width: 162px !important;
    margin-top: -10%;
  }

  /* Remove inline code */
.banner-container{
  display: flex;
  justify-content: center;
  margin: 2% 0 4% 0;
}

  .banner-content-outer{
    padding-left: 15%;
    padding-right: 10%;
    display: flex;
    align-items: center;
  }

  .study-materials{
    display: flex;
    justify-content: center;
    background: #f8f9ff;
    flex-direction: column;
    padding-bottom: 5%;
    align-items: center;
  } 
  .view_box-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
  }

  
  .values-ethos-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pl2{
    padding-left: 2%;
  }

  
  @media (max-width: 1279px) {
    .textlabel_about_us_heading {
      font-size: 40px !important;
    }
    .If-a-sporting-activity-text {
      font-size: 24px !important;
    }
    .feature_title_text {
      font-size: 20px !important;
    }
    .feature_desc_text {
      font-size: 14px !important;
    }
    .For-item-selected-title {
      font-size: 30px !important;
    }
    .For-item-not-selected-title {
      font-size: 24px !important;
    }
    .for_student_title {
      font-size: 20px !important;
    }
    .for_student_details {
      font-size: 16px !important;
    }
    .textlabel_values_ethos {
      font-size: 30px !important;
      margin-bottom: 15px !important;
    }
    .textlabel_values_ethos_desc {
      font-size: 16px !important;
    }
    .Our_values_textlabel {
      font-size: 30px !important;
    }
    .With_the_student_text {
      font-size: 20px !important;
    }
    .leadership_team_textlabel {
      font-size: 30px !important;
    }
    .leadership_team_details {
      font-size: 18px !important;
    }
    .leader_image {
      width: 140px;
      height: 165px;
    }
    .leader-profile-avatar {
      width: 140px;
      height: 165px;
    }
    .leader_name {
      font-size: 22px !important;
    }
    .leader_details {
      font-size: 16px !important;
    }
    .leader_less_details {
      font-size: 16px !important;
    }
    .leader_know_more {
      font-size: 18px !important;
    }
    .about_us_heading_underline {
      border-bottom: 10px solid #ffebc1;
      display: initial;
    }
  }

  @media (max-width: 959px) {
    .slick-slider.about-carousel .slick-prev {
      right: 15% !important;
    }
    .textlabel_about_us_heading {
      font-size: 31px !important;
    }
    .If-a-sporting-activity-text {
      font-size: 22px !important;
    }
    .leader_details {
      font-size: 14px !important;
    }
    .leader_less_details {
      font-size: 14px !important;
    }
    .textlabel_values_ethos {
      font-size: 28px !important;
    }
    .Our_values_textlabel {
      font-size: 28px !important;
    }
    .leadership_team_textlabel {
      font-size: 28px !important;
    }
    .leader_image {
      width: 110px;
      height: 135px;
    }
    .leader-profile-avatar {
      width: 110px;
      height: 135px;
    }
    .leader_name {
      font-size: 20px !important;
    }
    .For-item-selected-title {
      padding: 3% 0;
    }
    .For-item-not-selected-title {
      padding: 3% 0;
    }
    .centered-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .slick-slider.tiltSliderDots .slick-dots {
      display: flex !important;
    }
  }
  
  @media (max-width: 599px) { 
    .textlabel_about_us_heading {
      font-size: 30px !important;
    }
    .If-a-sporting-activity-text {
      font-size: 20px !important;
    }
    .feature_image_source { 
      height: 50px;
      width: 50px;
    }
    .feature_title_text {
      font-size: 18px !important;
    }
    .feature_desc_text {
      font-size: 14px !important;
    }
    .For-item-selected-title {
      font-size: 26px !important;
    }
    .For-item-not-selected-title {
      font-size: 22px !important;
    }
    .textlabel_values_ethos {
      font-size: 24px !important;
    }
    .Our_values_textlabel {
      font-size: 24px !important;
    }
    .leadership_team_textlabel {
      font-size: 24px !important;
    }
    .selected-line {
      padding-bottom: 10px;
    }
  
    .not-selected-line {
      padding-bottom: 10px;
    }
    .small-screen-no-space {
      padding: 0 !important;
      margin: 0 !important;
      margin-bottom: 12% !important;
    }
    .small-screen-leader-spacing {
      margin: 0 !important;
      padding-top: 20% !important;
    }
    .leader_rectangle {
      width: 82%;
    }
    .leader_details {
      height: 120px;
    }
    .With_the_student_text {
      font-size: 16px !important;
    }
    .leadership_team_details {
      font-size: 16px !important;
    }
    .for_student_title {
      font-size: 18px !important;
    }
  }
