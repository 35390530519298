.textlabel_batch_heading {
  font-family: Inter !important;
  font-size: 2.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}
/* .Hear-from-our-text {
  font-family: Inter !important;
  font-size: 2.6vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
  padding-left: 45px;
  margin-left: -25px !important;
  width: 600px;
} */


.language_view_box {
  width: 70px;
  height: 30px;
  margin: 0 13px 0 0;
  padding: 5px 13px;
  border-radius: 4px;
  background-color: #e1e9fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language_hindi {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
}

.bitmap-video-img {
  width: 100%;
  /* height: 310px; */
  object-fit: contain;
}

.overlap_view_box {
  /* width: 1170px;
    height: 164px;
    margin: 100px 0.4px 854px 2px;
    padding: 49px 61px 48px 55px; */
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  margin-top: -70px !important;
}

.img-event-1 {
  width: 67px;
  height: 67px;
  margin: 0 25px 0 0;
  object-fit: contain;
}

.morning-batch-text {
  margin: 0 0 11px;
  font-family: Inter !important;
  font-size: 1.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #464c5d;
}

.view-schedule-text {
  margin: 11px 0px 0 0 !important;
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #5580ea;
  text-decoration: underline;
}

.subjects {
  padding: 6% 13% !important;
  margin-bottom: 10% !important;
  border-radius: 5px !important;
}

.slick-slider.teach-you .slick-next {
  top: -30px !important;
  height: 30px;
  width: 34px;
}

.slick-slider.teach-you .slick-prev {
  top: -30px !important;
  right: 15px !important;
  left: auto !important;
  height: 30px;
  width: 34px;
}

  .slick-slider.teach-you .slick-prev{
    top: -30px !important;
    right: 15px !important;
    left: auto !important;
    height: 30px;
    width: 34px;
  }

  .Educator-Profile {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .Educator-bg-block {
    border-radius: 15px;
    background-color: #f8f9ff;
    padding: 5%;
  }

  .Educator-name {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    color: #394052;
  }

  .Educator-exp {
    font-family: Inter !important;
    font-size: 14px !important;
    color: #394052;
  }

  .Educator-years-experience-text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64 !important;
    color: #394052;
  }

  .plus-Likes {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .experience-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 5% !important;
  }

  .experience-text-dynamic {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .Years-of-Teaching-Text {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #394052;
    margin-left: 3% !important;
  }

  .achievements-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 2.2% !important;
  }

  .Best-Teacher-Award-Text {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #394052;
    margin-left: 1.5% !important;
  }

  .label-subjectname {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
    padding: 5% 0;
  }

  .Testimonials-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .Gamaho-Gemoy-Text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #000;
  }

  .Student-Text {
    font-family: Inter !important;
    font-size: 14px !important;
    color: #677788;
    margin-top: 10px !important;
  }

  .Student-Desc-Text {
    font-family: Inter !important;
    font-size: 16px !important;
    color: #000;
    line-height: 1.88 !important;
    margin-top: 18px !important;
  }

  .close-rectangle {
    width: 33px;
    height: 33px;
    border-radius: 3px;
    background-color: #5580ea;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view_box_subject_educator {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    padding: 10% 5px;
    width: 90%;
  }

  .selectedImage {
    height: 80px;
    width: 80px;
    opacity: 1;
    margin-left: 15px;
  }

  .notSelectedImage {
    height: 50px;
    width: 50px;
    opacity: 0.53;
    margin-left: 15px;
  }

  .listitem_subjectlogo {
    height: 40px;
    width: 40px;
  }

  .listitem_subjectname {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .react-player > video {
    object-fit: cover;
    border-radius: 8px;
    
  }
  /* .react-player{
    width: 600px!important;
    height: 300px!important;
    margin-top: 35px;
  } */

  .schedule-bg-block {
    border-radius: 15px;
    background-color: #f8f9ff;
    padding: 2% 3%;
  }

  .schedule-title {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal ;
    line-height: 1.45 !important;
    /* text-align: justify; */
    color: #394052;
  }

  .schedule_startdate {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06 !important;
    color: #394052;
  }

  .schedule-date-header {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86 !important;
    color: #394052;
  }

  .lecture-bg-box {
    border-radius: 5px;
    box-shadow: 0 1px 15px 3px rgba(230, 240, 254, 0.48);
    background-color: #fff;
    padding: 3% 5%;
    margin: 3% 0;
  }

  .lecture-title {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .unit-and-time-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #5c76b6;
  }

  .lecturer-image {
    margin-right: 11px;
  }

.lecturer-name {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.selected_date_view_box {
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 60%;
}

.selected_date {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  color: #394052;
  margin-left: 15px;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot {
  min-width: inherit;
  overflow: auto;
  box-shadow: 0 34px 24px -9px #edf3ff;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot
  .MuiPickersBasePicker-pickerView {
  max-width: inherit;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot
  .MuiPickersDay-daySelected {
  background-color: #5580ea;
  border-radius: 8px;
}

.scheduleCalendar .MuiPickersCalendar-week {
  justify-content: space-between !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-daysHeader {
  justify-content: space-between !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-dayLabel{
  color: #394052 !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-transitionContainer {
  color: #3f526d;
}
.scheduleCalendar .MuiPickersSlideTransition-transitionContainer > * {
  font-size: 16px;
  font-weight: bold;
}
.scheduleCalendar .MuiPickersDay-hidden {
  opacity: 0.5;
}
.scheduleCalendar .MuiPickersBasePicker-pickerView {
  min-width: 280px;
  width: 100%;
}
.list-style {
  overflow: auto;
  max-height: 60vh;
}
.scheduleCalendar {
  padding-right: 10%;
}
.scheduleCalendar .MuiPickersCalendarHeader-switchHeader {
  border-bottom: 0.5px solid lightgray;
}
.chapter-dynamic-width {
  width: 35%;
}

  @media (max-width: 1279px) {
    .textlabel_batch_heading {
      font-size: 38px !important;
    }
    .morning-batch-text {
      font-size: 22px !important;
    }
    .img-event-1 {
      height: 50px;
      width: 50px;
      margin: 0 10px 0 0;
    }
    .view-schedule-text {
      font-size: 14px !important;
    }
    .Educator-image{
      width: 90%;
    }
    .Years-of-Teaching-Text {
      font-size: 15px !important;
    }
    .Best-Teacher-Award-Text {
      font-size: 15px !important;
    }
    .listitem_subjectname {
      margin-left: 0;
    } 

    .batch-teacher-small {
      width: 100%;
    }
    .teacher-block {
      width: 100% !important;
      height: fit-content !important;
    }
    .MuiListItemAvatar-root {
      min-width: 45px !important;
    }
    .small-list-item {
      font-size: 14px !important;
    }
    .schedule-date-header {
      font-size: 20px !important;
    }
    .lecture-title {
      font-size: 16px !important;
    }
    .lecturer-name {
      font-size: 16px !important;
    }
    .chapter-dynamic-width {
      width: 50%;
    }
  }

  @media (max-width: 959px){
    .textlabel_batch_heading {
      font-size: 32px !important;
    }
    .morning-batch-text {
      font-size: 18px !important;
    }
    .batch_enroll_now_btn{
      width: 180px !important;
      height: 50px !important;
      margin-bottom: 20px !important;
    }
    .batch_enroll_now_btn_text{
      font-size: 18px !important;
    }
    .view-schedule-text {
      font-size: 12px !important;
    }
    .like-small-button {
      height: 40px !important;
      width: 110px !important;
      margin-left: 4% !important;
    }
    .listitem_subjectlogo {
      height: 36px;
      width: 36px;
    }
    .MuiListItemAvatar-root {
      min-width: 40px !important;
    }
    .batch-teacher-small {
      width: 75%;
    }
    .list-style {
      max-height: 30vh;
    }
    .schedule_startdate {
      font-size: 14px !important;
    }
    .scheduleCalendar {
      padding-right: 0;
      padding-bottom: 10px;
    }
    .selected_date_view_box {
     margin-bottom: 5px;
    }
    .scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot {
      box-shadow: none;
    }
    .chapter-dynamic-width {
      width: 70%;
    }
  }

  @media (max-width: 599px){
    .textlabel_batch_heading {
      font-size: 28px !important;
    }
    .morning-batch-text {
      font-size: 16px !important;
    }
    .img-event-1 {
      height: 25px;
      width: 25px;
      margin: 0 5px 0 0;
    }
    .view-schedule-text {
      font-size: 12px !important;
    }
    .spacing-60{
      padding: 30px 0;
    }
    .batch_enroll_now_btn{
      width: 150px !important;
      height: 50px !important;
      margin-bottom: 20px !important;
    }
    .batch_enroll_now_btn_text{
      font-size: 16px !important;
    }
    .Educator-years-experience-text {
      font-size: 12px !important;
    }
    .plus-Likes {
      font-size: 14px !important;
    }
    .experience-text {
      font-size: 14px !important;
    }
    .experience-text-dynamic {
      font-size: 14px !important;
    }
    .Years-of-Teaching-Text {
      font-size: 12px !important;
    }
    .Best-Teacher-Award-Text {
      font-size: 12px !important;
    }
    .achievements-text {
      font-size: 18px !important;
    }
    .Testimonials-text {
      font-size: 18px !important;
    }
    .Gamaho-Gemoy-Text {
      font-size: 18px !important;
    }
    .small-image {
      height: 20px;
      width: 20px;
    }
    .Student-Desc-Text { 
      font-size: 14px !important;
    }

    .selectedImage {
      height: 40px;
      width: 40px;
      margin-left: 5px;
    }
  
    .notSelectedImage {
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }

    .like-small-button {
      height: 35px !important;
      width: 85px !important;
    }
   
    .slick-slider.teach-you .slick-next{
      height: 24px;
      width: 26px;
      right: -5px !important;
    }
  
    .slick-slider.teach-you .slick-prev{
      right: 30px !important;
      height: 24px;
      width: 26px;
    }
    .batch-teacher-small {
      width: 100%;
    }
    .Educator-image {
      margin-bottom: 3% !important;
    }
    .scheduleCalendar {
      padding-right: 0;
      padding-bottom: 10px;
    }
    .small-screen-justify-start {
      justify-content: flex-start !important;
    }
    .schedule-title {
      font-size: 16px !important;
    }
    .schedule_startdate {
      font-size: 14px !important;
    }
    .selected_date_view_box {
      width: 90%;
      padding: 10px;
      margin: 5px 0;
    }
    .schedule-date-header {
      font-size: 18px !important;
    }
    .lecture-title {
      font-size: 14px !important;
    }
    .lecturer-name {
      font-size: 14px !important;
    }
    .lecturer-image {
      margin-right: 3px;
      height: 30px;
    }
    .unit-and-time-text {
      font-size: 12px !important;
    }
   .schedule-modal {
     overflow: auto;
     height: 90%;
     width: auto !important;
   }
 
    .overlap_view_box{
      margin-top: 0px !important;
    }
    .label-subjectname {
      font-size: 12px !important;
    }
    .small-chapter-name {
      font-size: 14px !important;
    }
    .small-topic-style {
      font-size: 12px !important;
      margin-left: 2% !important;
    }
    .font-14 {
      font-size: 14px !important;
    }
    .font-12 {
      font-size: 12px !important;
    }
  }
