.img-customer-service {
    width: 314px;
    height: 314px;
    object-fit: contain;
  }

.Thankyou-for-sharing {
    font-family: Inter !important;
    font-size: 24px !important;
    font-weight: bold !important;
    text-align: center;
    color: #3f526d;
    margin: 1% 0 !important
  }
  .thankyou-modal {
    justify-content: center !important;
    position: absolute !important;
    border-radius: 20px;
    height: 80%;
    width: 80%;
    /* padding: 3%; */
    overflow: auto;
}
.Our-team-would-get-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #3f526d;
  }

  @media (max-width: 599px) { 
    .img-customer-service {
      width: 90%;
      height: auto;
    }
    .Thankyou-for-sharing {
      font-size: 20px !important;
      width: 95%;
    }
    .Our-team-would-get-text {
      font-size: 12px !important;
      width: 95%;
    }

  }
